<template>
  <div>
    <div class="box-container">
      <small v-if="pspdList.length > 0" class="page-info is-pulled-right"
        ><strong>{{ pagination.startRowNum }}</strong> -
        <strong>{{ pagination.startRowNum + pspdList.length - 1 }}</strong>
        dari <strong>{{ pagination.total }}</strong></small
      >
      <div class="cleardiv"></div>
      <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
      <template v-if="isAdmin">
      <box
        v-for="pspd in pspdList"
        :key="pspd.id"
        :title="pspd.pspd"
        :subtitle1="pspd.npm"
      >
        <!-- TODO: sementara pencapaian tidak bisa diakses!!! -->
        <!-- :detailUrl="{ name: 'pencapaian-mahasiswa-detail', params: { id: pspd.pspd_id } }" -->
        <!-- :subtitle2="`Pengelolaan Kasus: ${pspd.pengelolaan_kasus} | Aktivitas Klinis: ${pspd.aktivitas} | Ujian: ${pspd.ujian} | Karya Ilmiah: ${pspd.ilmiah}`" -->
        
        <template v-slot:date>
        <a
          v-if="canDelete"
          class="del-btn is-pulled-right"
          @click="confirmPSPDDelete(pspd)"
        >
          <b-icon class="is-primary" size="is-small" icon="trash" />
        </a>
        <hasil-mark
          v-else
          class="is-pulled-right"
          :hasil="pspd.lulus"
        ></hasil-mark>
        </template>
      </box>
      </template>
      <template v-else>
      <box
        v-for="pspd in pspdList"
        :key="pspd.id"
        :title="pspd.pspd"
        :subtitle1="pspd.npm"
      >
        <template v-if="!isPSPD" v-slot:date>
          <hasil-mark
            class="is-pulled-right"
            :hasil="pspd.lulus"
          ></hasil-mark>
        </template>
      </box>
      </template>
      <box v-if="pspdList.length == 0">
        <template v-slot:content>
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>Belum ada mahasiswa yang terdaftar pada stase ini.</p>
            </div>
          </section>
        </template>
      </box>
    </div>
    <!-- <b-table striped hoverable :data="pspdList" :mobile-cards="false">
        <b-table-column field="no" label="No" v-slot="props">{{ pagination.startRowNum + props.index }}</b-table-column>
        <b-table-column field="npm" label="NPM" v-slot="props">{{ props.row.npm }}</b-table-column>
        <b-table-column field="pspd" label="Mahasiswa" v-slot="props">{{ props.row.pspd }}</b-table-column>
        <b-table-column v-if="!isPSPD" field="lulus" label="Lulus" v-slot="props">
        <hasil-mark :hasil="props.row.lulus"></hasil-mark>
        </b-table-column>
        <b-table-column v-if="canDelete" field="delete" class="has-text-right" v-slot="props">
        <a class="del-btn" @click="confirmPSPDDelete(props.row)">
            <b-icon class="is-primary" size="is-small" icon="trash" />
        </a>
        </b-table-column>

        <template slot="empty">
        <div class="content has-text-grey has-text-centered">
            <p>Tidak ada data mahasiswa terdaftar.</p>
        </div>
        </template>
    </b-table>-->
    <b-pagination
      v-if="pagination.showPagination"
      :total="pagination.total"
      :per-page="pagination.limit"
      :current.sync="pagination.page"
      order="is-centered"
      icon-pack="fas"
      @change="pageChange"
    ></b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PSPDList from "../models/pspdList.js";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";

export default {
  name: "PpdhTable",
  props: {
    stase: null,
  },
  mixins: [onlineCheckMixin, paginationMixin],
  data() {
    this.listMdl = new PSPDList(this.stase.id);
    return this.listMdl.getObservables();
  },
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    HasilMark: () => import("@/apps/core/components/HasilMark.vue"),
  },
  computed: {
    ...mapGetters("accounts", ["isPSPD", "isAdmin"]),
    canDelete() {
      return this.stase.extra_data.can_change; // &&
      // this.stase.stasepspd_set.length > 1
    },
  },
  methods: {
    fetchData() {
      this.listMdl.load(this.onLoaded);
    },
    confirmPSPDDelete(stasePspd) {
      this.$buefy.dialog.confirm({
        title: "Menghapus Data",
        message: "Anda yakin akan <b>menghapus</b> mahasiswa ini?",
        confirmText: "Hapus Mahasiswa",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.listMdl.delete(stasePspd.id, () => {
            const total = this.pagination.total - 1;
            if (this.pagination.prevPage > (Math.ceil(total / this.pagination.limit))) this.pagination.prevPage--;
            if (this.pagination.prevPage < 1) this.pagination.prevPage = 1;
            this.pagination.page = this.pagination.prevPage;
            this.listMdl.load();
          });
        },
      });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";
</style>